interface BaseConfigInterface {
  SimulationPage: {
    immobilisation: string;
  };
}

interface AppConfigInterface {
  [region: string]: BaseConfigInterface;
}

export enum Region {
  AU = "AU",
  UK = "UK",
  US = "US",
  DEMO = "DEMO",
  NCCC = "NCCC",
  SGCCC = "SGCCC",
  EVIQ = "EVIQ",
}

export const AppConfig: AppConfigInterface = {
  [Region.AU]: {
    SimulationPage: {
      immobilisation: "Immobilisation",
    },
  },
  [Region.US]: {
    SimulationPage: {
      immobilisation: "Immobilization",
    },
  },
  [Region.UK]: {
    SimulationPage: {
      immobilisation: "Immobilisation",
    },
  },
  [Region.DEMO]: {
    SimulationPage: {
      immobilisation: "Immobilization",
    },
  },
  [Region.NCCC]: {
    SimulationPage: {
      immobilisation: "Immobilization",
    },
  },
  [Region.SGCCC]: {
    SimulationPage: {
      immobilisation: "Immobilisation",
    },
  },
  [Region.EVIQ]: {
    SimulationPage: {
      immobilisation: "Immobilisation",
    },
  },
};

export const getAppConfigForTemplate = (regions: Region[]) => {
  if (!regions || regions.length === 0) {
    return AppConfig[Region.DEMO];
  }
  const region = regions[0];
  return AppConfig[region];
};
