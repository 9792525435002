import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import OarConstraintField from "./OarConstraintField";
import OarTwoValueTextField from "./OarTwoValueTextField";
import { useMutation, useQuery } from "@apollo/client";
import { decimalFormatter, LESS_THAN_OR_EQUAL } from "shared/utils";
import {
  ADD_OAR,
  GET_OAR_TEMPLATE,
  UPDATE_OAR,
  GET_ORGAN_LIST,
} from "graphql/OarQueries";
import { GET_SITE_GROUP_TEMPLATE } from "graphql/SiteGroupQueries";
import {
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  styled,
  useTheme,
  Button,
} from "@mui/material";
import GreenSwitch from "../base/GreenSwitch";
import OarSelect from "./OarSelect";
import OarDivider from "./OarDivider";

const autoCompleteStyle = {
  borderWidth: "1px",
  margin: "6px",
  paddingLeft: "6px",
  maxWidth: "82%",
};

const BoldGrid = styled(Grid)`
  font-weight: bold;
`;

const VALUE_UNIT_OPTIONS = [
  { value: "Gy", label: "Gy" },
  { value: "%", label: "%" },
];

interface ConstraintData {
  firstValue: number | null;
  firstValueUnit: string;
  operator: string;
  secondValue: number[];
  secondValueUnit: string;
  isVolumeData: boolean;
}
const sanitizeConstraintData = (dataList: any): ConstraintData[] => {
  return dataList.map((data: any): ConstraintData => {
    return {
      ...data,
      firstValue: data.firstValue !== "" ? parseFloat(data.firstValue) : null,
      secondValue: data.secondValue
        .filter((value: string): boolean => value !== "")
        .map((value: string): number => parseFloat(value)),
    };
  });
};

interface Props {
  setIsVisible: any;
  isVisible: any;
  index: number;
  oarData: any;
  siteGroupId: string;
  organsInUse: any;
}

const OarModal = ({
  setIsVisible,
  isVisible,
  index,
  oarData,
  siteGroupId,
  organsInUse,
}: Props): JSX.Element => {
  const theme = useTheme();
  const stateFromData = (data: any): any => ({
    siteGroupId: data.siteGroupId,
    index: index,
    organ: data.organ,
    volumeData:
      data.volumeConstraintData && data.volumeConstraintData.length
        ? data.volumeConstraintData.map((item: any): any => ({
            firstValue: decimalFormatter(item.firstValue),
            firstValueUnit: item.firstValueUnit,
            operator: item.operator,
            secondValue: item.secondValue,
            secondValueUnit: item.secondValueUnit,
            isVolumeData: true,
          }))
        : [
            {
              firstValue: "",
              firstValueUnit: "Gy",
              operator: LESS_THAN_OR_EQUAL,
              secondValue: [],
              secondValueUnit: "%",
            },
          ],
    dData:
      data.dConstraintData && data.dConstraintData.length
        ? data.dConstraintData.map((item: any): any => ({
            dosetoPrefixOperator: item.dosetoPrefixOperator,
            firstValue: decimalFormatter(item.firstValue),
            firstValueUnit: item.firstValueUnit,
            operator: item.operator,
            secondValue: item.secondValue,
            secondValueUnit: item.secondValueUnit,
            isVolumeData: false,
          }))
        : [
            {
              firstValue: "",
              firstValueUnit: "%",
              operator: LESS_THAN_OR_EQUAL,
              secondValue: [],
              secondValueUnit: "Gy",
            },
          ],
    maxValue: data.maxValue || [],
    maxValueUnit: data.maxValueUnit !== null ? data.maxValueUnit : "Gy",
    meanValueUnit: data.meanValueUnit !== null ? data.meanValueUnit : "Gy",
    meanValue: data.meanValue || [],
    validated: true,
    modalShow: true,
    alara: data.alara,
    isVisible: true,
    isEdit: true,
  });
  const defaultState = {
    siteGroupId:
      oarData && Object.keys(oarData).length && oarData.id
        ? oarData.id
        : siteGroupId,
    index: index,
    organ: "",
    maxValue: [""],
    meanValue: [""],
    maxValueUnit: "Gy",
    meanValueUnit: "Gy",
    volumeData: [
      {
        firstValue: undefined,
        firstValueUnit: "Gy",
        operator: LESS_THAN_OR_EQUAL,
        secondValue: [],
        secondValueUnit: "%",
      },
    ],
    dData: [
      {
        dosetoPrefixOperator: "",
        firstValue: undefined,
        firstValueUnit: "%",
        operator: LESS_THAN_OR_EQUAL,
        secondValue: [],
        secondValueUnit: "Gy",
      },
    ],
    validated: false,
    modalShow: true,
    alara: false,
    isVisible: true,
    isEdit: false,
  };
  const [state, setState] = useState(
    oarData ? stateFromData(oarData) : defaultState,
  );
  const [addOAR] = useMutation(ADD_OAR, {
    refetchQueries: [
      { query: GET_OAR_TEMPLATE, variables: { groupId: siteGroupId } },
      { query: GET_SITE_GROUP_TEMPLATE, variables: { groupId: siteGroupId } },
    ],
  });

  useEffect(() => {
    if (oarData) {
      setState({ state, ...stateFromData(oarData) });
    } else if (oarData === null) {
      setState({ ...defaultState });
    }
    // eslint-disable-next-line
  }, [oarData, isVisible]);

  const [updateOAR] = useMutation(UPDATE_OAR, {
    refetchQueries: [
      { query: GET_OAR_TEMPLATE, variables: { groupId: siteGroupId } },
      { query: GET_SITE_GROUP_TEMPLATE, variables: { groupId: siteGroupId } },
    ],
  });
  const { loading, data } = useQuery(GET_ORGAN_LIST, {
    variables: { siteGroupId: siteGroupId },
  });

  if (loading) return <div>Loading....</div>;
  const organList = data?.organList.map(
    (
      item: any,
    ): {
      label: string;
      value: string;
    } => ({
      label: item.organ,
      value: item.organ,
    }),
  );

  const onAddOAR = (): void => {
    if (
      !Array.isArray(state.meanValue) ||
      (state.meanValue.length === 1 && state.meanValue[0] === "")
    )
      state.meanValue = null;
    if (
      !Array.isArray(state.maxValue) ||
      (state.maxValue.length === 1 && state.maxValue[0] === "")
    )
      state.maxValue = null;
    state.volumeData = state.volumeData.filter(
      (vd: any): boolean =>
        vd.firstValue || (vd.secondValue && vd.secondValue.length),
    );
    state.dData = state.dData.filter(
      (dd: any): boolean =>
        dd.firstValue || (dd.secondValue && dd.secondValue.length),
    );

    addOAR({
      variables: {
        siteGroupId,
        organ: state.organ,
        volumeConstraintData: sanitizeConstraintData(state.volumeData),
        dConstraintData: sanitizeConstraintData(state.dData),
        maxValue:
          (state.maxValue &&
            state.maxValue.map((value: string) => parseFloat(value))) ||
          null,
        maxValueUnit: state.maxValueUnit || "Gy",
        meanValue:
          (state.meanValue &&
            state.meanValue.map((value: string) => parseFloat(value))) ||
          null,
        meanValueUnit: state.meanValueUnit || "Gy",
        alara: state.alara,
      },
    });
    setIsVisible(false);
  };

  const onEditOAR = (): void => {
    if (
      !Array.isArray(state.meanValue) ||
      (state.meanValue.length <= 1 && state.meanValue[0] === "")
    )
      state.meanValue = null;
    if (
      !Array.isArray(state.maxValue) ||
      (state.maxValue.length <= 1 && state.maxValue[0] === "")
    )
      state.maxValue = null;
    state.volumeData = state.volumeData.filter(
      (vd: any): boolean =>
        vd.firstValue || (vd.secondValue && vd.secondValue.length),
    );
    state.dData = state.dData.filter(
      (dd: any): boolean =>
        dd.firstValue || (dd.secondValue && dd.secondValue.length),
    );

    updateOAR({
      variables: {
        siteGroupId,
        index,
        organ: state.organ,
        volumeConstraintData: sanitizeConstraintData(state.volumeData),
        dConstraintData: sanitizeConstraintData(state.dData),
        maxValue:
          (state.maxValue &&
            state.maxValue.map((value: string) => parseFloat(value))) ||
          null,
        maxValueUnit: state.maxValueUnit || null,
        meanValue:
          (state.meanValue &&
            state.meanValue.map((value: string) => parseFloat(value))) ||
          null,
        meanValueUnit: state.meanValueUnit || null,
        alara: state.alara,
      },
    });
    setIsVisible(false);
  };

  const addConstraintVolumeData = (): void => {
    const volumeData = state.volumeData;
    volumeData.push({
      firstValue: null,
      firstValueUnit: "Gy",
      operator: LESS_THAN_OR_EQUAL,
      secondValue: null,
      secondValueUnit: "%",
    });
    setState({ ...state, volumeData: volumeData });
  };

  const addConstraintDData = (): void => {
    const dData = state.dData;
    dData.push({
      firstValue: null,
      firstValueUnit: "%",
      operator: LESS_THAN_OR_EQUAL,
      secondValue: null,
      secondValueUnit: "Gy",
    });
    setState({ ...state, dData });
  };

  const removeConstraintVolumeData = (index: any): void => {
    const volumeData = state.volumeData;
    volumeData.splice(index, 1);
    setState({ ...state, volumeData: volumeData });
  };

  const removeConstraintdData = (index: any): void => {
    const dData = state.dData;
    dData.splice(index, 1);
    setState({ ...state, dData: dData });
  };
  const cleanedOptions = organsInUse?.map((item: any): any => {
    if (item && item.toLowerCase() !== `${state.organ.toLowerCase()}`)
      return item.toLowerCase();
  });
  const unusedOrganList = organList.filter(
    (item: any): boolean =>
      !cleanedOptions.includes(item.value.toLowerCase()) ||
      item.value === state.organ,
  );
  const organDefaultValue = organList.find(
    (organ: { value: string; label: string }) => organ.value === state.organ,
  );
  return (
    <Dialog
      open={isVisible}
      onClose={() => setIsVisible(false)}
      scroll="paper"
      data-test-id="oar-modal"
      maxWidth="lg"
    >
      <DialogTitle style={{ fontWeight: "bold" }}>
        {state.isEdit ? "Edit OAR" : "Add OAR"}
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center">
          <BoldGrid item xs={2}>
            Organ
          </BoldGrid>
          <Grid item xs={10}>
            <Autocomplete
              sx={{ borderColor: theme.palette.grey[300] }}
              style={autoCompleteStyle}
              id="organ-name-select"
              options={unusedOrganList}
              autoHighlight
              getOptionLabel={(option: { label: string; value: string }) =>
                option.label
              }
              isOptionEqualToValue={(
                option: { label: string; value: string },
                value: { value: string },
              ): boolean => value?.value === option?.value}
              data-test-id="btn-confirmation-dialog-cancel"
              value={organDefaultValue || null}
              onChange={(_event: any, selectedOption: any): void => {
                setState({ ...state, organ: selectedOption?.value || "" });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                />
              )}
            />
          </Grid>
        </Grid>
        <OarDivider />
        <Grid container alignItems="center">
          <BoldGrid item xs={2}>
            Volume
          </BoldGrid>
          <Grid item xs>
            <OarConstraintField
              id="volumeData"
              label="Volume"
              value={state.volumeData}
              onChange={(index: number, value: any): void => {
                state.volumeData[index] = value || null;
                setState({ ...state });
              }}
              readOnly={state.alara}
              addConstraint={addConstraintVolumeData}
              removeConstraint={removeConstraintVolumeData}
              isVolumeData={true}
            />
          </Grid>
        </Grid>
        <OarDivider />
        <Grid container alignItems="center">
          <BoldGrid item xs={1}>
            D
          </BoldGrid>
          <Grid item xs>
            <OarConstraintField
              id="dData"
              label="D"
              value={state.dData}
              onChange={(index, value: any): void => {
                state.dData[index] = value || null;
                setState({ ...state });
              }}
              readOnly={state.alara}
              addConstraint={addConstraintDData}
              removeConstraint={removeConstraintdData}
              isVolumeData={false}
            />
          </Grid>
        </Grid>
        <OarDivider />
        <Grid container alignItems="center">
          <BoldGrid item xs={2}>
            Max
          </BoldGrid>
          <Grid style={{ paddingLeft: "6px" }}>{LESS_THAN_OR_EQUAL}</Grid>
          <Grid item xs={2}>
            <OarTwoValueTextField
              id="oar-max"
              value={state.maxValue || ""}
              readOnly={state.alara}
              onChange={(value: any[]): void => {
                setState({ ...state, maxValue: value });
              }}
            />
          </Grid>
          <Grid item xs>
            <OarSelect
              disableUnderline
              id="maxValueUnit"
              onChange={(event) => {
                if (!state.alara)
                  setState({ ...state, maxValueUnit: event.target.value });
              }}
              value={state.maxValueUnit || ""}
              disabled={state.alara}
              style={{
                verticalAlign: "bottom",
                border: "solid 1px",
                borderColor: theme.palette.grey[300],
              }}
            >
              {VALUE_UNIT_OPTIONS.map(
                (option: { label: string; value: any }, idx: number) => (
                  <MenuItem key={idx} value={option.value}>
                    {option.label}
                  </MenuItem>
                ),
              )}
            </OarSelect>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <BoldGrid item xs={2}>
            Mean
          </BoldGrid>
          <Grid style={{ paddingLeft: "6px" }}>{LESS_THAN_OR_EQUAL}</Grid>
          <Grid item xs={2}>
            <OarTwoValueTextField
              id="oar-mean"
              value={state.meanValue || ""}
              readOnly={state.alara}
              onChange={(value: any[]): void => {
                setState({ ...state, meanValue: value });
              }}
            />
          </Grid>
          <Grid item xs>
            <OarSelect
              disableUnderline
              id="meanValueUnit"
              onChange={(event) => {
                if (!state.alara)
                  setState({ ...state, meanValueUnit: event.target.value });
              }}
              value={state.meanValueUnit || ""}
              disabled={state.alara}
              style={{ verticalAlign: "bottom" }}
            >
              {VALUE_UNIT_OPTIONS.map(
                (option: { label: string; value: any }, idx: number) => (
                  <MenuItem key={idx} value={option.value}>
                    {option.label}
                  </MenuItem>
                ),
              )}
            </OarSelect>
          </Grid>
        </Grid>
        <OarDivider />
        <Grid container alignItems="center">
          <BoldGrid item xs={2}>
            Alara
          </BoldGrid>
          <Grid item xs={3}>
            <GreenSwitch
              checked={state.alara || false}
              onChange={(): void => {
                setState({
                  ...defaultState,
                  isEdit: state.isEdit,
                  organ: state.organ,
                  alara: !state.alara,
                });
              }}
              aria-label="login switch"
              data-test-id={"alara-switch"}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          data-test-id="oar-cancel"
          onClick={() => {
            setIsVisible(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          data-test-id="oar-confirm"
          title="Confirm"
          onClick={() => (state.isEdit ? onEditOAR() : onAddOAR())}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OarModal;
